









































import { Component, Prop, Vue } from 'vue-property-decorator';
import getLogger from '@/shared/logger/logger';
import PlayPauseButton from '@/ui/components/atoms/buttons/PlayPauseButton.vue';
import ButtonWithIcon from '@/ui/components/atoms/buttons/ButtonWithIcon.vue';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('VideoComposition');

@Component({
  name: 'VideoComposition',
  components: { PlayPauseButton, ButtonWithIcon },
})
export default class VideoComposition extends Vue {
  @Prop({ default: '' }) videoSource: string | undefined;
  private isVideoPlaying = false;
  private isVideoMuted = true;
  private videoElement = 'videoElement';
  private progressInPercent = 0;
  private playTime = 0;
  private fullDuration = 0;
  private previousTime = 0;
  private increment = 0;

  toggleVideoMuted() {
    this.isVideoMuted = !this.isVideoMuted;
  }

  get videoAudioIcon() {
    return this.isVideoMuted ? 'mdi-volume-mute' : 'mdi-volume-high';
  }

  get videoDuration() {
    return Number((this.$refs[this.videoElement] as HTMLVideoElement)?.duration.toFixed(2));
  }

  get displayPlayTime() {
    return this.formatDisplayTime(this.playTime);
  }

  get displayDuration() {
    return this.formatDisplayTime(this.fullDuration);
  }
  get isVideoLoaded() {
    return !!(this.$refs[this.videoElement] as HTMLVideoElement);
  }

  formatDisplayTime(time: number) {
    const currentMin = Math.floor(time / 60);
    const currentSec = Math.floor(time - currentMin * 60);
    const min = currentMin < 10 ? '0' + currentMin : currentMin;
    const sec = currentSec < 10 ? '0' + currentSec : currentSec;
    return min + ':' + sec;
  }

  initTimes() {
    this.fullDuration = (this.$refs[this.videoElement] as HTMLVideoElement)?.duration;
    this.playTime = (this.$refs[this.videoElement] as HTMLVideoElement)?.currentTime;
  }

  playOrPauseVideo() {
    return this.isVideoPlaying ? this.pauseVideo() : this.playVideo();
  }

  pauseVideo() {
    try {
      (this.$refs[this.videoElement] as HTMLVideoElement).pause();
      this.isVideoPlaying = false;
      this.updateProgressBar(false);
    } catch (e) {
      LOG.error(`Could not pause video: ${e}`);
    }
  }

  playVideo() {
    try {
      (this.$refs[this.videoElement] as HTMLVideoElement).play();
      this.isVideoPlaying = true;
      this.updateProgressBar(true);
    } catch (e) {
      LOG.error(`Could not play video: ${e}`);
    }
  }

  updateProgressBar(isRunning: boolean) {
    const timer = setInterval(() => {
      if (!isRunning) {
        clearInterval(timer);
      }
      this.progressInPercent =
        (Number((this.$refs[this.videoElement] as HTMLVideoElement)?.currentTime.toFixed(2)) / this.videoDuration) *
        100;
    }, 30);
  }

  updateProgress() {
    this.playTime = Number((this.$refs[this.videoElement] as HTMLVideoElement)?.currentTime.toFixed(2));
  }

  updateVideo() {
    this.pauseVideo();
    (this.$refs[this.videoElement] as HTMLVideoElement).currentTime = Number(
      ((this.progressInPercent / 100) * this.videoDuration).toFixed(2),
    );
  }

  resetVideo() {
    this.pauseVideo();
  }
}
