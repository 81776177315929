



















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import getLogger from '@/shared/logger/logger';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import i18n from '@/i18n';
import { groupBy as _groupBy } from 'lodash';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';
import type { Tag } from '@/core/tags/models/Tag';
import LabelChip from '@/ui/components/atoms/groupingItems/LabelChip.vue';
import OverflowChip from '@/ui/components/atoms/groupingItems/OverflowChip.vue';
import { getEmptyCampaignTag } from '@/ui/services/tags/tagService';
import { TagType } from '@/core/tags/enums/TagType';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('FormatFilter');

@Component({
  name: 'FormatFilter',
  components: { ButtonWithText, LabelChip, OverflowChip },
  computed: {},
})
export default class FormatFilter extends Vue {
  @Prop({ default: undefined }) campaignMedia: CampaignMedia[] | undefined;
  @Prop() selectedFilter!: Tag[];
  private temporarySelected: Tag[] = [];
  private showMenu = false;
  private readonly locale: keyof LanguageObject = i18n.i18next.language;
  private numberOfFilterTags = 3;

  mounted() {
    this.onSelectedFilterUpdated();
  }

  @Watch('selectedFilterUpdated')
  onSelectedFilterUpdated() {
    this.temporarySelected = this.selectedFilterUpdated;
  }

  get selectedFilterUpdated() {
    return this.selectedFilter || [];
  }

  get groupedFormats() {
    return _groupBy(this.campaignMedia, `format.value[${this.locale}]`);
  }

  get allFormats() {
    const newFormats: Tag[] = [];
    this.campaignMedia?.forEach((data) => {
      if (!newFormats.find((newFormat) => newFormat.id === data.format.id)) {
        newFormats.push(data.format);
      }
    });
    return newFormats;
  }

  getFormatId(formats: CampaignMedia[]) {
    return formats?.[0]?.format?.id;
  }

  resetFilter() {
    this.temporarySelected = [];
  }
  removeLabel(index: number) {
    this.temporarySelected.splice(index, 1);
    this.updateParent(this.temporarySelected);
  }
  updateParent(updatedFilters: Tag[]) {
    this.$emit('updated', updatedFilters);
    this.showMenu = false;
  }

  getTagsFromID(selectedIds: string[]) {
    return selectedIds
      .map((id) => {
        if (this.allFormats?.length > 0) {
          return this.allFormats.find((format: Tag) => format.id == id);
        } else {
          return getEmptyCampaignTag(TagType.FORMAT);
        }
      })
      .slice(0, this.numberOfFilterTags);
  }
}
