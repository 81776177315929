




import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonWithIcon from '@/ui/components/atoms/buttons/ButtonWithIcon.vue';

@Component({
  name: 'PlayPauseButton',
  components: { ButtonWithIcon },
})
export default class PlayPauseButton extends Vue {
  @Prop({ default: false }) showPaused?: boolean;

  get playOrPauseIcon() {
    return this.showPaused ? 'mdi-pause' : 'mdi-play';
  }

  emitClick() {
    this.$emit('click');
  }
}
