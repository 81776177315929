

































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import getLogger from '@/shared/logger/logger';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import { StorageFileQuality } from '@/core/campaign/enums/Campaign';
import VideoComposition from '@/ui/components/atoms/VideoComposition.vue';
import { downloadMediumUseCase } from '@/core/media/usecases/downloadMediumUseCase';
import { copyStringToClipboard } from '@/core/media/usecases/copyMediumUrlToClipboard';
import { filterAndSortThumbnails } from '@/ui/util/thumbnail';
import { Loader } from '@/ui/util/decorators/loader';
import { UserStoreGetters } from '@/ui/stores/UserStore';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('CampaignMediaCard');

@Component({
  name: 'CampaignMediaCard',
  components: { VideoComposition },
})
export default class CampaignMediaCard extends Vue {
  @Prop({ default: undefined }) media: CampaignMedia | undefined;
  private showPreview = false;
  private isTooltipCopiedToClipboardShown = false;
  private currentThumbnailIndex = 0;
  private updateThumbnailTimeoutId = -1;

  get defaultImage() {
    return require('@/ui/assets/thumbnail/image-empty.png');
  }

  get fileName() {
    return this.media?.fileName;
  }
  get zones() {
    return this.media?.zones;
  }

  get channels() {
    return [...new Map(this.media?.zones?.map((zone) => [zone.group?.id, zone.group])).values()];
  }

  get format() {
    return this.$tagValue(this.media?.format);
  }

  get language() {
    return this.$tagValue(this.media?.language);
  }

  get source() {
    return this.media?.files?.find(
      ({ quality, isOld }) =>
        (quality === StorageFileQuality.ORIGINAL || quality === StorageFileQuality.CONVERTED) && !isOld,
    )?.storagePath;
  }

  get sortedThumbnails() {
    return filterAndSortThumbnails(this.media?.thumbnails);
  }

  get thumbnail() {
    const getThumbnail = this.sortedThumbnails
      ? this.sortedThumbnails[this.currentThumbnailIndex]?.storagePath
      : undefined;
    const getImageFile = this.media?.files?.find(
      (file) => file.quality === StorageFileQuality.ORIGINAL && this.isImage(),
    )?.storagePath; // if media is image
    return getThumbnail ? getThumbnail : getImageFile ? getImageFile : this.defaultImage;
  }

  get showTags() {
    return this.media?.tags && this.media.tags.length > 0;
  }

  get showChannels() {
    return this.channels.length > 0;
  }

  get canDownload() {
    return this.$store.getters[UserStoreGetters.USER_CAN_DOWNLOAD];
  }

  get showZones() {
    return this.zones && this.zones.length > 0;
  }

  @Loader
  async downloadMediaClicked() {
    if (this.source && this.media?.fileName) {
      await downloadMediumUseCase(this.source, this.media.fileName);
    }
  }

  togglePreview() {
    this.stopThumbnailLoop();
    this.showPreview = !this.showPreview;
  }

  copyLinkClicked() {
    if (this.source) {
      copyStringToClipboard(this.source);
      this.showCopiedToClipboardTooltip();
    }
  }

  shareLinkClicked() {
    if (this.media?.fileName && this.source) {
      window.location.href = `mailto:?subject=Media Cloud&body=Link to ${this.media.fileName}: ${this.source}`;
    }
  }

  showCopiedToClipboardTooltip() {
    this.isTooltipCopiedToClipboardShown = true;
    setTimeout(() => {
      this.isTooltipCopiedToClipboardShown = false;
    }, 1500);
  }

  isImage() {
    return !!this.media?.contentType?.match('image.*');
  }

  isVideo() {
    return !!this.media?.contentType?.match('video.*');
  }

  onHoverThumbnail() {
    if (this.updateThumbnailTimeoutId !== -1) {
      return;
    }
    if (this.sortedThumbnails && this.sortedThumbnails.length > 1) {
      this.updateThumbnail();

      this.updateThumbnailTimeoutId = setInterval(() => {
        this.updateThumbnail();
      }, 1250);
    }
  }

  updateThumbnail() {
    if (this.currentThumbnailIndex + 1 === this.sortedThumbnails?.length) {
      this.currentThumbnailIndex = 0;
    } else {
      this.currentThumbnailIndex++;
    }
  }

  stopThumbnailLoop() {
    clearInterval(this.updateThumbnailTimeoutId);
    this.updateThumbnailTimeoutId = -1;
  }

  include() {
    return [document.querySelector('.included')];
  }
}
