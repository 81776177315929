import { container } from 'tsyringe';
import { MediaService } from '@/core/media/services/MediaService';

export const downloadMediumUseCase = async (source: string, fileName: string): Promise<void> => {
  const mediaService = container.resolve<MediaService>('MediaService');

  const response = await mediaService.getMediumBlob(source);
  const dataType = response.headers['content-type'];

  const binaryData = [];
  binaryData.push(response.data);

  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
  downloadLink.setAttribute('download', fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  return;
};
