


































import { Component, Vue } from 'vue-property-decorator';
import getLogger from '@/shared/logger/logger';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import i18n from '@/i18n';
import { TagType } from '@/core/tags/enums/TagType';
import type { Tag } from '@/core/tags/models/Tag';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('CampaignMediaOrderingFilter');

@Component({
  name: 'CampaignMediaOrderingFilter',
  components: {},
  computed: {},
})
export default class CampaignMediaOrderingFilter extends Vue {
  private readonly selectedNumber = 0;
  private readonly locale: keyof LanguageObject = i18n.i18next.language;
  private filterOptions: Array<Partial<Tag>> = [];

  mounted() {
    this.filterOptions = [
      {
        type: TagType.FORMAT,
        value: {
          'de-CH': i18n.i18next.t('filter.campaign-detail.format'),
          'fr-CH': i18n.i18next.t('filter.campaign-detail.format'),
          'it-CH': i18n.i18next.t('filter.campaign-detail.format'),
          'en-CH': i18n.i18next.t('filter.campaign-detail.format'),
        },
      },
      {
        type: TagType.LANGUAGE,
        value: {
          'de-CH': i18n.i18next.t('filter.campaign-detail.language'),
          'fr-CH': i18n.i18next.t('filter.campaign-detail.language'),
          'it-CH': i18n.i18next.t('filter.campaign-detail.language'),
          'en-CH': i18n.i18next.t('filter.campaign-detail.language'),
        },
      },
      {
        type: TagType.SUBJECT,
        value: {
          'de-CH': i18n.i18next.t('filter.campaign-detail.subject'),
          'fr-CH': i18n.i18next.t('filter.campaign-detail.subject'),
          'it-CH': i18n.i18next.t('filter.campaign-detail.subject'),
          'en-CH': i18n.i18next.t('filter.campaign-detail.subject'),
        },
      },
    ];
  }

  updateParent() {
    this.$emit('updated', this.selectedNumber);
  }
}
