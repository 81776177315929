



































import { Component, Mixins } from 'vue-property-decorator';
import CampaignMediaCard from '@/ui/components/molecules/campaigns/CampaignMediaCard.vue';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import CampaignDetailFilter from '@/ui/components/organisms/filter/CampaignDetailFilter.vue';
import { cloneDeep as _cloneDeep, groupBy as _groupBy, orderBy as _orderBy, map as _map } from 'lodash';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import i18n from '@/i18n';
import type { Campaign } from '@/core/campaign/models/Campaign';
import { CampaignStoreGetters } from '@/ui/stores/CampaignStore';
import { MediumStatus } from '@/core/campaign/enums/Campaign';
import { filteredUniqueMediaZones } from '@/ui/util/filteredUniqueMediaZones';
import { nextRouteIsNotDetailsView } from '@/ui/util/clearCampaignDetails';

@Component({
  name: 'CampaignDetailMedia',
  components: { CampaignDetailFilter, CampaignMediaCard },
})
export default class CampaignDetailMedia extends Mixins(NavigationMixin) {
  private filteredZones: string[] = [];
  private filteredFormats: string[] = [];
  private filteredLanguages: string[] = [];
  private filteredSubjects: string[] = [];
  private orderingFilterSelection = 0;
  private orderingFilters: string[] = [];
  private readonly locale: keyof LanguageObject = i18n.i18next.language;

  mounted() {
    this.updateFiltersForPreviouslyPreselectedDetailZones();
    this.setOrderingFilters();
  }

  beforeDestroy() {
    nextRouteIsNotDetailsView(this.$route);
  }

  get selectedFormatsFilter() {
    return this.$store.state.campaignStore.selectedDetailFormats || [];
  }

  get selectedLanguagesFilter() {
    return this.$store.state.campaignStore.selectedDetailLanguages || [];
  }

  get selectedSubjectFilter() {
    return this.$store.state.campaignStore.selectedDetailSubjects || [];
  }

  get selectedDetailZones() {
    return this.$store.state.campaignStore.selectedDetailZones || [];
  }

  get campaignDetailMediaZone() {
    return filteredUniqueMediaZones(this.campaignMedia);
  }

  get filteredMedias(): CampaignMedia[] {
    if (!this.campaignMedia) {
      return [];
    }
    return this.campaignMedia.filter((media) => {
      let hasMediaFilteredZone = true;
      let hasMediaFilteredFormat = true;
      let hasMediaFilteredLanguage = true;
      let hasMediaFilteredSubject = true;

      if (this.isZoneFilterSet) {
        hasMediaFilteredZone = this.filteredZones.some((filteredZone) =>
          media.zones.find((mediaZone) => mediaZone.id === filteredZone),
        );
      }
      if (this.isFormatFilterSet) {
        hasMediaFilteredFormat = this.filteredFormats.some((filteredFormat) => {
          return media.format.id === filteredFormat;
        });
      }
      if (this.isLanguageFilterSet) {
        hasMediaFilteredLanguage = this.filteredLanguages.some((filteredLanguage) => {
          return media.language.id === filteredLanguage;
        });
      }
      if (this.isSubjectFilterSet) {
        hasMediaFilteredSubject = this.filteredSubjects.some((filteredSubject) => {
          return media.subject?.id === filteredSubject;
        });
      }
      return (
        hasMediaFilteredZone &&
        hasMediaFilteredFormat &&
        hasMediaFilteredLanguage &&
        hasMediaFilteredSubject &&
        media.status !== MediumStatus.DELETED
      );
    });
  }

  get isZoneFilterSet(): boolean {
    return this.filteredZones?.length > 0;
  }
  get isFormatFilterSet(): boolean {
    return this.filteredFormats?.length > 0;
  }
  get isLanguageFilterSet(): boolean {
    return this.filteredLanguages?.length > 0;
  }
  get isSubjectFilterSet(): boolean {
    return this.filteredSubjects?.length > 0;
  }

  get sortedMediaByOrderingFilter() {
    const orderedFiles = _orderBy(this.filteredMedias, `format.value[${this.locale}]`, 'desc');
    return _groupBy(orderedFiles, this.orderingFilters[this.orderingFilterSelection]);
  }

  get selectedCampaign(): Campaign | undefined {
    return this.$store.getters[CampaignStoreGetters.GET_SELECTED_CAMPAIGN];
  }

  get campaignMedia(): CampaignMedia[] | undefined {
    return this.selectedCampaign?.media;
  }

  get allPanelIndices(): number[] {
    const numPanels = Object.keys(this.sortedMediaByOrderingFilter).length;
    return [...Array(numPanels).keys()];
  }

  setOrderingFilters() {
    this.orderingFilters = [
      `format.value[${this.locale}]`,
      `language.value[${this.locale}]`,
      `subject.value[${this.locale}]`,
      `zones.group.value[${this.locale}]`,
    ];
  }

  updateFiltersForPreviouslyPreselectedDetailZones() {
    this.updateFilters(
      this.selectedDetailZones,
      this.selectedFormatsFilter,
      this.selectedLanguagesFilter,
      _map(this.selectedSubjectFilter, 'id'),
      0,
    );
  }

  updateFilters(zoneTags: string[], format: string[], language: string[], subject: string[], num: number) {
    this.filteredZones = _cloneDeep(zoneTags);
    this.filteredFormats = _cloneDeep(format);
    this.filteredLanguages = _cloneDeep(language);
    this.filteredSubjects = _cloneDeep(subject);
    this.orderingFilterSelection = num;
  }

  catchUndefined(name: string) {
    if (
      name === 'undefined' ||
      name === 'Undefined' ||
      name === 'Undefiniert' ||
      name === 'Indefinito' ||
      name === 'Indéfini'
    ) {
      name = this.$t('campaign.campaign-detail.no-subject-group');
    }
    return name;
  }
}
