



















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import getLogger from '@/shared/logger/logger';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import i18n from '@/i18n';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';
import { groupBy as _groupBy } from 'lodash';
import type { Tag } from '@/core/tags/models/Tag';
import LabelChip from '@/ui/components/atoms/groupingItems/LabelChip.vue';
import OverflowChip from '@/ui/components/atoms/groupingItems/OverflowChip.vue';
import { getEmptyCampaignTag } from '@/ui/services/tags/tagService';
import { TagType } from '@/core/tags/enums/TagType';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('LanguageFilter');

@Component({
  name: 'LanguageFilter',
  components: { ButtonWithText, LabelChip, OverflowChip },
  computed: {},
})
export default class LanguageFilter extends Vue {
  @Prop({ default: undefined }) campaignMedia: CampaignMedia[] | undefined;
  @Prop() selectedFilter!: Tag[];
  private showMenu = false;
  private temporarySelected: Tag[] = [];
  private readonly locale: keyof LanguageObject = i18n.i18next.language;
  private numberOfFilterTags = 3;

  mounted() {
    this.onSelectedFilterUpdated();
  }

  @Watch('selectedFilterUpdated')
  onSelectedFilterUpdated() {
    this.temporarySelected = this.selectedFilterUpdated;
  }

  get selectedFilterUpdated() {
    return this.selectedFilter || [];
  }

  get languages() {
    return _groupBy(this.campaignMedia, `language.value[${this.locale}]`);
  }

  get allLanguages() {
    const newLanguages: Tag[] = [];
    this.campaignMedia?.forEach((data) => {
      if (!newLanguages.find((newLanguage) => newLanguage.id === data.language.id)) {
        newLanguages.push(data.language);
      }
    });
    return newLanguages;
  }

  updateParent(updatedFilters: Tag[]) {
    this.$emit('updated', updatedFilters);
    this.showMenu = false;
  }

  getLanguageId(languages: CampaignMedia[]) {
    return languages?.[0]?.language?.id;
  }

  resetFilter() {
    this.temporarySelected = [];
  }
  removeLabel(index: number) {
    this.temporarySelected.splice(index, 1);
    this.updateParent(this.temporarySelected);
  }
  getTagsFromID(selectedIds: string[]) {
    return selectedIds
      .map((id) => {
        if (this.allLanguages?.length > 0) {
          return this.allLanguages.find((format: Tag) => format.id == id);
        } else {
          return getEmptyCampaignTag(TagType.LANGUAGE);
        }
      })
      .slice(0, this.numberOfFilterTags);
  }
}
