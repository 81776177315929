





































import { Component, Prop, Vue } from 'vue-property-decorator';
import ChannelFilter from '@/ui/components/molecules/filter/ChannelFilter.vue';
import LabelFilter from '@/ui/components/molecules/filter/LabelFilter.vue';
import FormatFilter from '@/ui/components/molecules/filter/FormatFilter.vue';
import CampaignMediaOrderingFilter from '@/ui/components/molecules/filter/CampaignMediaOrderingFilter.vue';
import LanguageFilter from '@/ui/components/molecules/filter/LanguageFilter.vue';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import SubjectFilter from '@/ui/components/molecules/filter/SubjectFilter.vue';
import getLogger from '@/shared/logger/logger';
import type { Tag } from '@/core/tags/models/Tag';
import { CampaignStoreMutations } from '@/ui/stores/CampaignStore';
import { map as _map } from 'lodash';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('CampaignDetailFilter');

@Component({
  name: 'CampaignDetailFilter',
  components: {
    SubjectFilter,
    LanguageFilter,
    CampaignMediaOrderingFilter,
    FormatFilter,
    LabelFilter,
    ChannelFilter,
  },
})
export default class CampaignDetailFilter extends Vue {
  @Prop({ default: undefined }) campaignMedia: CampaignMedia[] | undefined;
  @Prop() zones: Tag[] | undefined;
  @Prop() selectedZones!: Tag[];
  @Prop() selectedSubjectFilter!: Tag[];
  @Prop() selectedLanguagesFilter!: Tag[];
  @Prop() selectedFormatsFilter!: Tag[];
  private filteredZones: Tag[] = [];
  private filteredFormats: Tag[] = [];
  private filteredLanguages: Tag[] = [];
  private filteredSubjects: string[] = [];
  private orderingFilter = 0;

  updateZones(zones: Tag[]) {
    this.filteredZones = zones;
    this.$store.commit(CampaignStoreMutations.SET_FILTER_DETAIL_ZONES, zones);
    this.updateParent();
  }

  updateFormats(formats: Tag[]) {
    this.$store.commit(CampaignStoreMutations.SET_FILTER_DETAIL_FORMATS, formats);
    this.filteredFormats = formats;
    this.updateParent();
  }

  updateLanguages(languages: Tag[]) {
    this.$store.commit(CampaignStoreMutations.SET_FILTER_DETAIL_LANGUAGES, languages);
    this.filteredLanguages = languages;
    this.updateParent();
  }

  updateSubjects(tags: Tag[]) {
    this.$store.commit(CampaignStoreMutations.SET_FILTER_DETAIL_SUBJECTS, tags);
    this.filteredSubjects = _map(tags, 'id');
    this.updateParent();
  }

  updateOrderingFilter(num: number) {
    this.orderingFilter = num;
    this.updateParent();
  }

  resetAllFilters() {
    this.$store.commit(CampaignStoreMutations.RESET_CAMPAIGN_DETAIL);
    this.filteredZones = [];
    this.filteredFormats = [];
    this.filteredLanguages = [];
    this.filteredSubjects = [];
    this.updateParent();
  }

  updateParent() {
    this.$emit(
      'updated',
      this.filteredZones,
      this.filteredFormats,
      this.filteredLanguages,
      this.filteredSubjects,
      this.orderingFilter,
    );
  }
}
